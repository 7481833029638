import styled from "styled-components"
import { GridLayout } from "../../../GridLayout"
import device from "../../../theme/devices"

//@ts-ignore
import { ReactComponent as MLogo } from "../../../icons/logo-full.svg"

export const Container = styled(GridLayout)<{ wait: boolean }>`
  cursor: ${({ wait }) => (wait ? "wait" : "default")};
  height: 100vh;
  grid-template-rows: 1fr repeat(3, auto) 1fr;
  position: relative;
  color: white;
  background: rgba(65, 15, 15, 0.1);
`

export const Title = styled.h1`
  grid-row: 2;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0;
  text-align: center;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 1 / 13;
  }
`

export const Loader = styled.div<{ percent: number }>`
  grid-row: 3;
  height: 0;
  width: ${props => props.percent * 100}%;
  border: 1px solid #da2020;
  transition: width 250ms ease-out;
  box-sizing: border-box;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 1 / 13;
  }
`

export const Percentage = styled.div`
  grid-row: 4;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: 0;

  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 1 / 13;
  }
`

export const OffScreen = styled.div`
  display: block;
  visibility: hidden;

  position: fixed;
  top: -500000000000px;
  left: -500000000000px;
`

export const Logo = styled(MLogo)`
  color: white;
  position: absolute;
  right: 20px;
  top: 9px;
  width: 123px;
`
